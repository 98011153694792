exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-src-content-post-01-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/post-01.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-src-content-post-01-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-src-content-post-02-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/post-02.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-src-content-post-02-mdx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-divert-tsx": () => import("./../../../src/pages/divert.tsx" /* webpackChunkName: "component---src-pages-divert-tsx" */),
  "component---src-pages-flext-tsx": () => import("./../../../src/pages/flext.tsx" /* webpackChunkName: "component---src-pages-flext-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kami-tsx": () => import("./../../../src/pages/kami.tsx" /* webpackChunkName: "component---src-pages-kami-tsx" */),
  "component---src-pages-kinetic-type-tsx": () => import("./../../../src/pages/kinetic-type.tsx" /* webpackChunkName: "component---src-pages-kinetic-type-tsx" */),
  "component---src-pages-noli-tsx": () => import("./../../../src/pages/noli.tsx" /* webpackChunkName: "component---src-pages-noli-tsx" */),
  "component---src-pages-random-rules-tsx": () => import("./../../../src/pages/random-rules.tsx" /* webpackChunkName: "component---src-pages-random-rules-tsx" */)
}

